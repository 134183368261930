import { NextPage } from 'next';
import React from 'react';

interface Props {
  heading?: string;
  subheading?: string;
}

export const Hero: NextPage<Props> = (props: Props) => (
  <div className="flex bg-[url('/images/fallback/hero.png')] bg-center bg-no-repeat bg-cover h-[calc(100vh-64px)] w-full">
    <div className="container md:flex flex-end align-center hidden md:w-1/2" />
    <div className="container flex flex-col p-8 justify-center items-center w-full md:w-1/2 max-w-full">
      <h1 className="text-white text-center ml-0 mr-0 pb-2 text-2xl font-bold">
        {props.heading}
      </h1>
      <h2 className="text-white text-center ml-0 mr-0">{props.subheading}</h2>
    </div>
  </div>
);

Hero.defaultProps = {
  heading: 'A platform for the entire music production lifecycle.',
  subheading:
    'The music industry can be daunting. Let us help you navigate it from conception to release.',
};
