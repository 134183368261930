/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Card, Image } from '@polyphonic/ui';

// const [_, FeatureType] = featureBuilder.use();

// TODO: correct types when sanity query builder supports images
// interface FeatureTypeMap {
//   [items: string]: typeof FeatureType;
// }

export const Features = ({ items }: any) => {
  return (
    <div className="flex container flex-col max-w-full items-center">
      {items.map((item: any) => (
        <div
          className="container flex mt-12 mb-20 flex-col sm:flex-row max-w-6xl"
          key={item._id}
        >
          <div className="flex flex-col pr-4 w-100 sm:w-1/2 justify-center">
            <h2 className="m-0 pb-4 leading-10 text-4xl font-bold">
              {item.featureTitle}
            </h2>
            <p className="m-0 text-justify mb-6 tracking-wider pr-0 xs:pr-0 sm:pr-0 md:pr-10 lg:pr-10 xl:pr-10">
              {item.featureDescription}
            </p>
          </div>
          <Card className="flex justify-center align-center p-10 flex-shrink-1 xs:w-100 xs:h-100 sm:w-1/2 sm:h-100 md:w-1/2 md:h-110 lg:w-1/2 lg:h-100 xl:w-1/2 xl:h-100 items-center bg-background shadow-morphic">
            <Image
              className="h-60 text-center"
              src={item.featureImage}
              alt={item.featureTitle}
              width={item.featureImageWidth}
              height={item.featureImageHeight}
            />
          </Card>
        </div>
      ))}
    </div>
  );
};
