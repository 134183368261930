import { GetStaticProps } from 'next';
import type { ReactElement } from 'react';

import { featureBuilder } from '@polyphonic/admin/schemas/feature';

import { Features, Hero, Newsletter, Tweet } from '@/components/home';
import { DefaultLayout } from '@/components/layouts';
import { indexQuery } from '@/utils/sanity/queries';
import { sanityClient } from '@/utils/sanity/sanity.server';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const [_, FeatureType] = featureBuilder.use();

interface FeatureTypeMap {
  [features: string]: typeof FeatureType;
}

const Home = ({ allFeatures }: FeatureTypeMap) => {
  return (
    <>
      <Hero />
      <Features items={allFeatures} />
      <Newsletter
        heading="Get Beta Access"
        subheading="Take part in shaping the future of music. No spam, we promise."
      />
      <Tweet />
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const allFeatures = await sanityClient.fetch<typeof FeatureType>(indexQuery);
  return {
    props: { allFeatures },
  };
};

Home.getLayout = function getLayout(page: ReactElement) {
  return (
    <DefaultLayout title={'Polyphonic'}>
      <>{page}</>
    </DefaultLayout>
  );
};

export default Home;
