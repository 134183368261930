import React from 'react';

import { Avatar, Card, Link } from '@polyphonic/ui';

export const Tweet = () => (
  <div className="container flex justify-center p-10 ml-0 mr-0 pr-0 pl-0 max-w-full bg-background">
    <Card className="w-[458px] animate-neon">
      <blockquote
        style={{ backgroundColor: 'transparent', padding: '20px', margin: 0 }}
      >
        <div className="flex align-center p-0 items-center">
          <a
            href="https://twitter.com/elonmusk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Avatar
              alt="Elon"
              role="presentation"
              src="/elon.jpg"
              className="w-20 h-20 text-large"
            />
          </a>
          <a
            href="https://twitter.com/elonmusk"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '10px',
            }}
          >
            <h6 className="m-0" title="Elon Musk">
              Elon Musk
            </h6>
            <p className="m-0" title="@elonmusk">
              @elonmusk
            </p>
          </a>
        </div>
        <p className="my-4">Music is a limbic resonator</p>
        <div className="flex p-0 align-center">
          <Link
            href="https://twitter.com/elonmusk/status/1246995778746421248"
            target="_blank"
            rel="noopener noreferrer"
            className="color-foreground flex hover:color-[#3b94d9]"
          >
            <time
              title="Time Posted: Mon, 06 Apr 2020 02:58:56 GMT"
              dateTime="2020-04-06T02:58:56.000Z"
            >
              4:58 AM - Apr 6, 2020
            </time>
          </Link>
        </div>
      </blockquote>
    </Card>
  </div>
);
