import { zodResolver } from '@hookform/resolvers/zod';
import { NextPage } from 'next';
import React, { useState } from 'react';
import { Message, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button, CircularProgress, Input, toast } from '@polyphonic/ui';

import { api } from '@/utils/api';

interface Props {
  heading?: string;
  subheading?: string;
}

type SubscribeInput = {
  name: string;
  email: string;
};

const NewsletterSchema = z.object({
  name: z.string().min(1, { message: 'An artist name is required' }),
  email: z
    .string()
    .min(1, { message: 'An email address is required' })
    .email('Please enter a valid email address'),
});

export const Newsletter: NextPage<Props> = (props) => {
  const {
    handleSubmit,
    register,

    formState: { errors },
  } = useForm<SubscribeInput>({
    resolver: zodResolver(NewsletterSchema),
    mode: 'onChange',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [signup, setSignup] = useState<[string, string]>(['', '']);
  const { refetch } = api.misc.newsletter.useQuery(
    {
      name: signup[0],
      email: signup[1],
    },
    {
      enabled: false,
    },
  );

  const handleSubscribe: SubmitHandler<SubscribeInput> = async ({
    name,
    email,
  }) => {
    setIsSubmitting(true);
    await setSignup([name, email]);
    const result = await refetch();
    if (result?.data?.success) {
      setIsSubmitting(false);
      toast.success(result.data.message);
    } else {
      setIsSubmitting(false);
      toast.error(result?.data?.message as Message);
    }
  };

  return (
    <div
      className="flex bg-[url('/images/fallback/asteroids.png')] bg-center bg-no-repeat bg-cover relative h-[calc(100vh-122px)] w-full"
      id="newsletter"
    >
      <div className="flex justify-center align-center flex-col mx-auto p-[0 20px]">
        <form
          onSubmit={handleSubmit(handleSubscribe)}
          className="mt-4 w-100 bg-background p-[20px] rounded-[20px] max-w-[500px] mx-auto"
        >
          <div className="flex flex-col">
            <h2 className="text-center text-3xl mb-2">{props.heading}</h2>
            <p className="text-center pb-6">{props.subheading}</p>
            <Input
              size="sm"
              variant="faded"
              id="name"
              {...register('name')}
              className="mb-8"
              label="Artist Name"
              errorMessage={errors.name?.message}
            />
            <Input
              size="sm"
              variant="faded"
              id="email"
              {...register('email')}
              className="mb-8"
              label="Email Address"
              errorMessage={errors.email?.message}
            />
            <Button
              className="max-w-20 mx-auto mb-6"
              type="submit"
              aria-label="Subscribe"
              color="primary"
              radius="full"
            >
              {isSubmitting ? (
                <CircularProgress
                  color="default"
                  strokeWidth={3}
                  aria-label="Progress indicator"
                />
              ) : (
                <p className="font-bold">Subscribe</p>
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
