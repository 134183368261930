import { defineDocument } from 'sanity-typed-queries';

export const { document: featureDocument, builder: featureBuilder } =
  defineDocument('feature', {
    featureTitle: {
      title: 'Feature Title',
      type: 'string',
      required: true,
      validation: (Rule) => Rule.required(),
    },
    featureDescription: {
      title: 'Feature Description',
      type: 'text',
      required: true,
      validation: (Rule) => Rule.required(),
    },
    featureImage: {
      title: 'Feature Image',
      type: 'image',
      required: true,
      validation: (Rule) => Rule.required(),
    },
  });
